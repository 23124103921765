'use client';

import '@/polyfills/ton';

import { TonConnectUIProvider, THEME } from '@tonconnect/ui-react';
import { SDKProvider, useLaunchParams } from '@telegram-apps/sdk-react';
import { retrieveLaunchParams, initSwipeBehavior } from '@telegram-apps/sdk-react';
import semver from 'semver';
import { useEffect, useRef } from 'react';
import TelegramMiniApp from './InitializeTelegramMiniApp';
import { isLocalOrBata } from '@/utils/checkRuntime';
import dynamic from 'next/dynamic'

const DynamicOKXTonConnectUIProvider = dynamic(
  () => import('@okxconnect/tonui-react').then((mod) => mod.OKXTonConnectUIProvider),
  { ssr: false }
);

const domainUrl = process.env.NEXT_PUBLIC_DOMAIN_URL;
const telegramMiniAppUrl = process.env.NEXT_PUBLIC_TELEGRAM_DIRECT_LINK as `${string}://${string}`;

const _includeWallets = [
  // includeWallets: [
  //   {
  //     appName: 'tonwallet',
  //     name: 'TON Wallet',
  //     imageUrl: 'https://wallet.ton.org/assets/ui/qr-logo.png',
  //     aboutUrl:
  //       'https://chrome.google.com/webstore/detail/ton-wallet/nphplpgoakhhjchkkhmiggakijnkhfnd',
  //     universalLink: 'https://wallet.ton.org/ton-connect',
  //     jsBridgeKey: 'tonwallet',
  //     bridgeUrl: 'https://bridge.tonapi.io/bridge',
  //     platforms: ['chrome', 'android'],
  //   },
  //   {
  //     appName: 'safepalwallet',
  //     name: 'SafePal',
  //     imageUrl:
  //       'https://s.pvcliping.com/web/public_image/SafePal_x288.png',
  //     aboutUrl: 'https://www.safepal.com/download',
  //     jsBridgeKey: 'safepalwallet',
  //     platforms: ['ios', 'android', 'chrome', 'firefox'],
  //   },
  // ],
];

export default function TelegramTonProvider({
  value,
  children,
}: {
  value: any
  children: React.ReactNode;
}) {
  const debug = useLaunchParams()?.initData?.startParam === 'debug';
  const app = useRef();

  // const manifestUrl = useMemo(() => {
  //   return new URL('tonconnect-manifest.json', window.location.href).toString();
  // }, []);

  useEffect(() => {
    const launchParams = retrieveLaunchParams(); 
    const [swipeBehavior] = initSwipeBehavior();
    if (semver.gt(semver.coerce(launchParams.version), semver.coerce('7.6'))) {
      swipeBehavior.disableVerticalSwipe();
    }
  }, []);

  // Enable debug mode to see all the methods sent and events received.
  useEffect(() => {
    if (debug || isLocalOrBata) {
      import('eruda').then((lib) => lib.default.init());
    }
  }, [debug]);

  return (
    <SDKProvider acceptCustomStyles debug={debug}>
      <DynamicOKXTonConnectUIProvider
        dappMetaData={{
          "name": "Pina Mania",
          "icon": "https://assets.pinamania.com/pinamania.png"
        }}
        uiPreferences={{ theme: THEME.LIGHT }}
        restoreConnection={true}
        actionsConfiguration={{
          returnStrategy: 'tg://resolve',
        }}
      >
        <TonConnectUIProvider
          manifestUrl={`https://assets.pinamania.com/tonconnect-manifest.json`}
          uiPreferences={{ theme: THEME.LIGHT }}
          actionsConfiguration={{
            twaReturnUrl: telegramMiniAppUrl,
          }}>
          <TelegramMiniApp>
            {children}
          </TelegramMiniApp>
        </TonConnectUIProvider>
      </DynamicOKXTonConnectUIProvider>
    </SDKProvider>
  );
}
