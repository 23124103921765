'use client';

import Image from 'next/image';
import { useMemo } from 'react';
import { usePathname } from 'next/navigation';

import leafImage from '@/public/images/lobby/leaf.png';

function endsWith(str: string, list: Array<string>) {
  for (let i = 0; i < list.length; i += 1) {
    if (str.endsWith(list[i])) {
      return true;
    }
  }
  return false;
}

const nameMap = {
  shareReward: "Today’s Rewards",
};

function getPageName(str) {
  const t = str.split('/').slice(-1)[0];
  return nameMap[t] || t;
}

export default function FlowerHead() {
  const pathname: string = usePathname();

  if (!endsWith(pathname, ['friends', 'task', 'store', 'settings', 'shareReward'])) {
    return null;
  }

  const pageName = useMemo(() => {
    return getPageName(pathname);
  }, [pathname]);

  return (
    <div className="absolute w-full z-10">
      <Image className="absolute w-full" src={leafImage} alt={pageName} />
      <div className="font-comicbd text-center mt-12 text-2xl font-bold capitalize">
        {pageName}
      </div>
    </div>
  );
}
