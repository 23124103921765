'use client';

import { Buffer } from 'buffer';

const setup = () => {
  if (typeof window === 'undefined') return;

  // @ts-ignore
  window.Buffer = Buffer;

  // https://github.com/ton-org/ton-core/issues/40
  // @ts-ignore
  Uint8Array.prototype.copy = Buffer.prototype.copy;
}

setup();
